import Vue from "vue";
import {
  mdiAccount,
  mdiAlertCircle,
  mdiBank,
  mdiCardAccountPhoneOutline,
  mdiCash,
  mdiCashRefund,
  mdiClose,
  mdiCreditCardOutline,
  mdiCurrencyUsd,
  mdiCurrencyUsdCircleOutline,
  mdiEmail,
  mdiPlusCircleOutline,
  mdiScaleBalance,
  mdiTrashCanOutline,
} from "@mdi/js";
import {
  ModoPago,
  Pago,
  UpdateOperacion,
  Kiosko,
  Banco,
  EstatusPago,
  EstatusOperacion,
  TipoPago,
  UpdateEstatusOperacion,
  TipoDenominacion,
} from "apd.sistemapagos.models";
import { formatISO } from "date-fns";
import { ApiErrorResponse, ApiHeh, ApiSistemaPagos, AxiosHttpClient } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import spinnerModule from "@/store/modules/spinnerModule";
import snackbarModule from "@/store/modules/snackbarModule";
import { Guid } from "guid-typescript";
import operacionesModule from "@/store/modules/operacionesModule";
import commonDataModule from "@/store/modules/commonDataModule";
import { CryptoModel } from "@/models/cryptoModel";

export default Vue.extend({
  name: "PagarNoDispensadoComponent",
  props: {
    operacion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Icons: {
        cash: mdiCurrencyUsdCircleOutline,
        close: mdiClose,
        card: mdiCreditCardOutline,
        phone: mdiCardAccountPhoneOutline,
        user: mdiAccount,
        bank: mdiBank,
        currency: mdiCurrencyUsd,
        alertCircle: mdiAlertCircle,
        plus: mdiPlusCircleOutline,
        delete: mdiTrashCanOutline,
        dollar: mdiCash,
        balance: mdiScaleBalance,
        refund: mdiCashRefund,
        email: mdiEmail,
      } as Record<string, string>,
      select: "Clabe",
      items: [{ tipo: "Tarjeta" }, { tipo: "Clabe" }, { tipo: "Efectivo" }],
      dialog: false,
      pago: [] as Pago[],
      nodispensado: 0,
      cnd: 0,
      cuentaDestino: this.operacion.pagos[0].cuentaDestino,
      idOperacion: this.operacion.id,
      estatusPago: EstatusPago.Dispensado,
      checkbox: false,
      checkboxBalance: false,
      estatusP: [
        { id: 2, estatus: "Dispensado" },
        { id: 3, estatus: "NoDispensado" },
      ],
      modoPago: ModoPago.Egreso,
      crypto: [] as CryptoModel[],
      pagosArray: [] as Pago[],
      datosOperacion: {} as UpdateOperacion,
      cajero:
        this.$store.getters.appMode == "production"
          ? "38f43523-6544-4639-b517-f31737b36a17"
          : "8ca45794-50ce-49a6-8204-6052725e64f0",
      banco: "",
      label: "Monto",
      dialogConfirmacion: false,
      validarCnd: true,
      prefijoClabe: "",
      selectBanco: {} as Banco,
      nombreUsuario: "",
      numeroCuenta: "",
      formValid: true,
      email: this.operacion.email,
      telefono: this.operacion.telefono,
      pagos: this.operacion.pagos as Pago[],
      balancear: false,
      loadingCard: false,
      denominaciones: [
        { valor: 100, tipo: 0 },
        { valor: 50, tipo: 0 },
        { valor: 10, tipo: 1 },
        { valor: 5, tipo: 1 },
        { valor: 1, tipo: 1 },
      ],
    };
  },
  computed: {
    rules() {
      return this.$store.state.validationRules;
    },
    getNombreCajero() {
      return (): string | undefined => {
        return this.operacion.kiosko.nombrePublico;
      };
    },
    disableRemove(): boolean {
      return this.pagosArray.length > 1 ? false : true;
    },
    estatus(): number {
      return this.operacion.estatusOperacion;
    },
    saldoOperacion(): number {
      if (this.operacion.estatusOperacion == EstatusOperacion.Cancelada) {
        return 0;
      } else {
        return (
          this.operacion.importeServicio +
          this.operacion.importeComision +
          this.operacion.importeComisionTarjeta +
          this.operacion.importeComisionOtros +
          this.operacion.impuestoServicio +
          this.operacion.impuestoComision +
          this.operacion.impuestoComisionTarjeta +
          this.operacion.impuestoComisionOtros
        );
      }
    },
    saldoPagos(): number {
      let saldo = 0;

      if (this.pagos != null && this.pagos.length > 0) {
        this.pagos.forEach((pago) => {
          if (pago.modoPago == ModoPago.Ingreso) {
            saldo += pago.monto;
          }
          if (pago.modoPago == ModoPago.Egreso) {
            saldo -= pago.monto;
          }
        });
      }
      return saldo;
    },
    saldoNoDispensado(): number {
      return this.pagos
        .filter((p) => p.estatusPago == EstatusPago.NoDispensado)
        .reduce((p, i) => p + Number(i.monto), 0);
    },
    pagosRelacionados(): number {
      let pagosR = 0;
      if (operacionesModule.relacionadas.length > 0 && operacionesModule.relacionadas != null) {
        operacionesModule.relacionadas.forEach((relacionada) => {
          if (relacionada.tipoRelacion == 0 && relacionada.operacionRelacionada.pagos != undefined) {
            relacionada.operacionRelacionada.pagos.forEach((pago) => {
              if (pago.estatusPago == EstatusPago.Dispensado) {
                pagosR += pago.monto;
              }
            });
          }
        });
      }
      return pagosR;
    },
    total(): number {
      this.pagosArray.forEach((pago) => {
        if (pago.cantidad != undefined && pago.denominacion != undefined) {
          pago.monto = pago.cantidad * pago.denominacion;
        }
      });
      return this.pagosArray.reduce((r, i) => r + Number(i.monto), 0);
    },
    max(): number {
      return this.saldoNoDispensado - this.pagosRelacionados;
    },
    disableBtn(): boolean {
      const max = this.saldoNoDispensado - this.pagosRelacionados;
      let balancear = this.diferencia;
      if (balancear < 0) {
        balancear = -this.diferencia;
      }
      if (this.diferencia != 0) {
        if (
          this.diferencia < 0 ||
          (this.total != this.cnd && this.total != this.diferencia) ||
          this.operacion.estatusOperacion == EstatusOperacion.Aplicada ||
          this.operacion.estatusOperacion == EstatusOperacion.Cobrada ||
          this.operacion.estatusOperacion == EstatusOperacion.AplicadaIncompleta ||
          this.operacion.estatusOperacion == EstatusOperacion.CobradaIncompleta ||
          this.operacion.estatusOperacion == EstatusOperacion.Incompleta ||
          !this.checkboxBalance
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.select != "Efectivo") {
          return !this.formValid;
        }
        if (this.select == "Efectivo" && (this.total == 0 || this.total > max || this.cajero == "")) {
          return true;
        } else {
          return false;
        }
      }
    },
    disableArray: function (): boolean {
      if (this.max != this.saldoNoDispensado) {
        return false;
      } else {
        return this.select == "Efectivo" && this.balancear ? false : true;
      }
    },
    diferencia: function (): number {
      if (this.operacion.pagos[0].numeroCuenta == undefined) {
        if (
          this.operacion.estatusOperacion != EstatusOperacion.Aplicada &&
          this.operacion.estatusOperacion != EstatusOperacion.Cobrada
        ) {
          return this.saldoPagos;
        } else {
          return this.saldoPagos - this.saldoOperacion;
        }
      } else {
        return 0;
      }
    },
    cajerosList(): Kiosko[] {
      return commonDataModule.Cajeros.filter((e) => e.tipoKiosko != 0).sort((a, b) =>
        a.nombrePublico > b.nombrePublico ? 1 : -1,
      );
    },
    bancosList(): Banco[] {
      return commonDataModule.Banco.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    },
    hide(): boolean {
      if (this.validarCnd && this.balancear) {
        return true;
      } else {
        return false;
      }
    },
    validarClabe(): boolean {
      return false;
    },
    hideCnd() {
      if (this.saldoNoDispensado <= 0 || this.diferencia != 0) {
        return true;
      } else {
        return false;
      }
    },
    hideBalance() {
      const ingresado = this.pagos
        .filter((p) => p.modoPago == ModoPago.Ingreso)
        .reduce((p, i) => p + Number(i.monto), 0);
      if (
        this.diferencia != 0 &&
        ingresado != 0 &&
        (this.operacion.estatusOperacion != EstatusOperacion.Aplicada ||
          this.operacion.estatusOperacion != EstatusOperacion.Cobrada ||
          this.operacion.estatusOperacion != EstatusOperacion.AplicadaIncompleta ||
          this.operacion.estatusOperacion != EstatusOperacion.CobradaIncompleta ||
          this.operacion.estatusOperacion != EstatusOperacion.NoDispensadoPagado ||
          this.operacion.estatusOperacion != EstatusOperacion.NoDispensadoPorPagar)
      ) {
        return false;
      } else {
        return true;
      }
    },
    alertBalance() {
      if (this.diferencia != 0) {
        if (
          this.diferencia > 0 &&
          this.operacion.estatusOperacion != EstatusOperacion.Aplicada &&
          this.operacion.estatusOperacion != EstatusOperacion.Cobrada &&
          this.operacion.estatusOperacion != EstatusOperacion.AplicadaIncompleta &&
          this.operacion.estatusOperacion != EstatusOperacion.CobradaIncompleta &&
          this.operacion.estatusOperacion != EstatusOperacion.Incompleta
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    async load(valor: string) {
      spinnerModule.Show();
      await commonDataModule.LoadCajeros();
      await commonDataModule.LoadBancos();
      await operacionesModule.LOAD_RELACIONADAS(this.operacion.id);

      this.pagosArray = [
        {
          id: Guid.create().toString(),
          idOperacion: this.idOperacion,
          idFormaPago: "20544a93-7392-4743-aa52-c05eff03e684",
          tipoPago: TipoPago.Servicio,
          modoPago: this.modoPago,
          monto: 0,
          banco: this.selectBanco.nombre,
          tipoCuentaDestino: this.operacion.pagos[0].tipoCuentaDestino,
          cuentaDestino: this.cuentaDestino,
          estatusPago: this.estatusPago,
          folioAutorizacion: "Efectivo",
          created: formatISO(Date.now()),
          updated: formatISO(Date.now()),
        },
      ];
      if (
        valor == "balance" &&
        this.operacion.estatusOperacion != EstatusOperacion.Cobrada &&
        this.operacion.estatusOperacion != EstatusOperacion.Aplicada &&
        this.operacion.estatusOperacion != EstatusOperacion.AplicadaIncompleta &&
        this.operacion.estatusOperacion != EstatusOperacion.CobradaIncompleta
      ) {
        this.pagosArray = [];
        this.pagosArray.push({
          id: Guid.create().toString(),
          idOperacion: this.idOperacion,
          idFormaPago: "20544a93-7392-4743-aa52-c05eff03e684",
          tipoPago: TipoPago.Servicio,
          modoPago: ModoPago.Egreso,
          monto: 0,
          cantidad: 0,
          denominacion: 0,
          tipoDenominacion: 0,
          tipoCuentaDestino: this.pagos[0].tipoCuentaDestino,
          cuentaDestino: this.pagos[0].cuentaDestino,
          estatusPago: EstatusPago.NoDispensado,
          folioAutorizacion: "Efectivo",
          created: formatISO(Date.now()),
          updated: formatISO(Date.now()),
        });
        this.balancear = true;
        this.cajero = this.operacion.kiosko.id;
        //this.select = "Efectivo";
        this.cuentaDestino = this.operacion.pagos[0].cuentaDestino;
        if (this.balancear && this.diferencia > 0) {
          this.cnd = this.diferencia;

          this.modoPago = ModoPago.Egreso;
          this.estatusPago = EstatusPago.NoDispensado;
        } else {
          this.cnd = -this.diferencia;
          this.estatusPago = EstatusPago.Ingresado;
          this.modoPago = ModoPago.Ingreso;
        }
      } else {
        this.balancear = false;
        this.cnd = this.saldoNoDispensado - this.pagosRelacionados;
        this.idOperacion = Guid.create().toString();
        this.cuentaDestino = "Reembolso";
        this.label = "No dispensado";
        this.select = "Clabe";
      }

      this.dialog = true;
      spinnerModule.Hide();
    },
    prefijo() {
      this.selectBanco = commonDataModule.BancoById(this.banco);
      this.prefijoClabe = this.selectBanco.prefijoClabe;
    },
    async change() {
      this.numeroCuenta = "";

      if (this.select == "Efectivo" && !this.balancear) {
        this.pagosArray = [];
        const pago = this.operacion.pagos as Pago[];
        await pago
          .filter((p) => p.estatusPago == EstatusPago.NoDispensado)
          .forEach((pago) => {
            this.pagosArray.push({
              id: Guid.create().toString(),
              idOperacion: this.idOperacion,
              idFormaPago: "20544a93-7392-4743-aa52-c05eff03e684",
              tipoPago: TipoPago.Servicio,
              modoPago: this.modoPago,
              cantidad: pago.cantidad,
              denominacion: pago.denominacion,
              monto: pago.monto,
              tipoCuentaDestino: this.operacion.pagos[0].tipoCuentaDestino,
              cuentaDestino: this.cuentaDestino,
              estatusPago: this.estatusPago,
              folioAutorizacion: "Efectivo",
              created: formatISO(Date.now()),
              updated: formatISO(Date.now()),
            });
          });
      } else {
        this.pagosArray = [
          {
            id: Guid.create().toString(),
            idOperacion: this.idOperacion,
            idFormaPago: "20544a93-7392-4743-aa52-c05eff03e684",
            tipoPago: TipoPago.Servicio,
            modoPago: this.modoPago,
            monto: 0,
            banco: this.selectBanco.nombre,
            tipoCuentaDestino: this.operacion.pagos[0].tipoCuentaDestino,
            cuentaDestino: this.cuentaDestino,
            estatusPago: this.estatusPago,
            folioAutorizacion: "Efectivo",
            created: formatISO(Date.now()),
            updated: formatISO(Date.now()),
          },
        ];
      }
    },
    add() {
      this.pagosArray.push({
        id: Guid.create().toString(),
        idOperacion: this.idOperacion,
        idFormaPago: "20544a93-7392-4743-aa52-c05eff03e684",
        tipoPago: TipoPago.Servicio,
        modoPago: this.modoPago,
        cantidad: 0,
        denominacion: 0,
        monto: 0,
        tipoCuentaDestino: this.operacion.pagos[0].tipoCuentaDestino,
        cuentaDestino: this.cuentaDestino,
        estatusPago: this.estatusPago,
        folioAutorizacion: "Efectivo",
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
      });
    },
    remove(index: number) {
      this.pagosArray.splice(index, 1);
    },
    async llenar() {
      spinnerModule.Show();
      if (this.balancear) {
        this.estatusPago;
        this.pagosArray.forEach((pago) => {
          pago.estatusPago = this.estatusPago;
        });
      }

      if (
        this.numeroCuenta != undefined &&
        this.numeroCuenta != "" &&
        this.nombreUsuario != "" &&
        this.nombreUsuario != undefined &&
        this.select != "Efectivo"
      ) {
        const clientHeh = new AxiosHttpClient(getUrl(ApiList.HEH, this.$store.getters.appMode));
        clientHeh.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
        const apiHeh = new ApiHeh(clientHeh);

        this.crypto.push(
          {
            value: this.nombreUsuario,
            group: 0,
          },
          { value: this.numeroCuenta, group: 0 },
        );
        await apiHeh
          .Encode(this.crypto)
          .then((e) => {
            const data = e as CryptoModel[];
            this.pagosArray[0].nombreCuenta = data[0].value;
            this.pagosArray[0].numeroCuenta = data[1].value;
          })
          .catch(() => {
            this.pagosArray[0].nombreCuenta = this.nombreUsuario;
            this.pagosArray[0].numeroCuenta = this.numeroCuenta;
            snackbarModule.Show("Error al encriptar los datos");
          });
        this.pagosArray[0].banco = this.selectBanco.nombre;
        this.pagosArray[0].monto = this.cnd;
      }
      this.dialogConfirmacion = true;
      spinnerModule.Hide();
    },
    async agregar() {
      this.loadingCard = true;
      spinnerModule.Show;
      const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      clientSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP = new ApiSistemaPagos(clientSP);

      const idUsuario = this.$store.getters["oidcStore/oidcUser"].sub;
      if (!this.balancear) {
        if (this.select != "Efectivo" && this.telefono != undefined) {
          this.datosOperacion = { idOperacion: this.operacion.id, telefono: this.telefono, email: this.email };
          await apiSP.ActualizarDatosOperacion(this.datosOperacion).catch((error) => {
            const x = error as ApiErrorResponse;
            x.apiError.mensajeUsuario;
            snackbarModule.Show(x.apiError.mensajeUsuario);
          });
        }
        await apiSP
          .PagarNoDispensado(this.operacion.id, this.cajero, this.pagosArray, idUsuario)
          .then((e) => {
            if (e == true) {
              snackbarModule.ShowSuccess("Cambio no dispensado pagado");
              this.vaciar();
            }
          })
          .catch((e) => {
            const x = e as ApiErrorResponse;
            x.apiError.mensajeUsuario;
            snackbarModule.Show(x.apiError.mensajeUsuario);
          })
          .finally(() => {
            this.cnd = this.saldoNoDispensado - this.pagosRelacionados;
          });
      } else {
        const update = {
          idOperacion: this.operacion.id,
          estatusOperacion: EstatusOperacion.Cancelada,
        } as UpdateEstatusOperacion;
        await apiSP
          .BalancearOperacion(this.operacion.id, idUsuario, this.pagosArray)
          .then((e) => {
            if (e == true) {
              snackbarModule.ShowSuccess("Operacion balanceada");
              this.select = "Clabe";
              if (this.operacion.estatus != EstatusOperacion.Cancelada) {
                apiSP.ActualizarEstatusOperacion(this.operacion.id, update);
              }

              this.vaciar();
            }
          })
          .catch((e) => {
            const x = e as ApiErrorResponse;
            x.apiError.mensajeUsuario;
            snackbarModule.Show(x.apiError.mensajeUsuario);
          });
      }
      spinnerModule.Hide();
      this.dialogConfirmacion = false;
      this.loadingCard = false;
    },
    async vaciar() {
      spinnerModule.Show();
      this.pagosArray = [
        {
          id: this.operacion.id,
          idOperacion: this.idOperacion,
          idFormaPago: "20544a93-7392-4743-aa52-c05eff03e684",
          tipoPago: TipoPago.Servicio,
          modoPago: this.modoPago,
          cantidad: 0,
          denominacion: 0,
          monto: 0,
          tipoCuentaDestino: this.operacion.pagos[0].tipoCuentaDestino,
          cuentaDestino: this.cuentaDestino,
          estatusPago: this.estatusPago,
          folioAutorizacion: "Efectivo",
          created: formatISO(Date.now()),
          updated: formatISO(Date.now()),
        },
      ];
      await operacionesModule.LoadOperaciones();
      //const op = operacionesModule.OperacionById(this.operacion.id);
      /*if (op.pagos != undefined) {
        this.pagos = op.pagos;
      }*/
      await operacionesModule.LOAD_RELACIONADAS(this.operacion.id);
      await operacionesModule.VALIDAR_NO_DISPENSADO(this.operacion.id).then(() => {
        if (!operacionesModule.validarNoDispensado) {
          this.validarCnd = false;
        } else {
          this.validarCnd = true;
        }
      });

      this.dialog = false;
      spinnerModule.Hide();
    },
    changeDenominacion(index: Pago) {
      const denominacion = this.denominaciones.find((d) => d.valor == index.denominacion);
      index.tipoDenominacion = denominacion?.tipo as TipoDenominacion;
    },
  },
});
